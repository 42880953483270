import "./App.scss";
import 'normalize.css';
import { ReactComponent as EmailIcon } from "./assets/images/envelope-solid.svg";
import useWindowDimensions from './hooks/window';


function spliceIntoChunks(arr, chunkSize) {
  const res = [];
  while (arr.length > 0) {
    const chunk = arr.splice(0, chunkSize);
    res.push(chunk);
  }
  return res;
}

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    return (images[item.replace("./", "")] = r(item));
  });
  return images;
}

const images = importAll(
  require.context("../public/photos", false, /\.(png|jpe?g|svg)$/)
);

function App() {
  const { height, width } = useWindowDimensions();
  const desiredColumns = Math.ceil(width / 240);

  const imageKeysArray = Object.entries(images).map(([key, value]) => key);
  const columns = Math.ceil(imageKeysArray.length / desiredColumns);
  const imageKeyChunks = spliceIntoChunks(imageKeysArray, columns);

  return (
    <>
      <header>
        <h1>
          Alison Morgan <span>Photography</span>
        </h1>
        <a href="mailto:alison@alisonmorganphotography.com">
          Contact Alison
          <EmailIcon className="emailIcon" />
        </a>
      </header>
      <div id="grid">
        {Object.entries(imageKeyChunks).map(([i, chunk]) => {
          return (
            <div className="column">
              {Object.entries(chunk).map(([i, image]) => {
                return (
                  <>
                    <img
                      src={images[image]}
                      alt={image}
                      loading="lazy"
                      draggable="false"
                    />
                  </>
                );
              })}
            </div>
          );
        })}
      </div>
    <footer>
      <p>&copy; Alison Morgan Photography, All Rights Reserved.</p>
    </footer>
    </>
  );
}

export default App;
